import React from 'react';
import {createRoot} from 'react-dom/client';
import Main from "./Main";
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

const rootElement = document.getElementById('root');
if(!rootElement){
    throw new Error("Could not find the root element");
}

const root = createRoot(rootElement);
root.render(
<Main />
);