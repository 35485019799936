
import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Login(props){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const apiUrl = props.apiUrl;
    const setIsLoggedIn = props.setIsLoggedIn;
    const setShowModal = props.setShowModal;
    const showModal = props.showModal;

    const handleModalClose = () => {
        setShowModal(false);
        setUsername('');
        setPassword('');
    };
    
    const updateUsername = (event) => {
        setUsername(event.target.value);
    }
    
    const updatePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = { user: username, pass: password };

        //attempt to log in and get a token
        fetch(apiUrl + '/user/auth', {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(response => {
            if(response.status !== 200){
                alert("There was an error logging in");
                return;
            }

            //if success, store the token
            response.text().then(function(bodyText){
                localStorage.setItem("AuthToken", bodyText);
                setIsLoggedIn(true);
                setShowModal(false);
                setUsername('');
                setPassword('');
            });
        })
        .catch((error) => {
            alert(error);
            console.error('Error:', error);
        });
    };

    useEffect(() => {
        setShowModal(props.showModal);
    });

    return(
        <Modal show={showModal} onHide={handleModalClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Log In</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="userName" className="form-label">Username</label>
                        <input type="text" name="username" className="form-control" id="userName" value={username} onChange={updateUsername} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" name="password" className="form-control" id="password" value={password} onChange={updatePassword} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
                    <Button type="submit" variant="primary" onClick={handleSubmit}>Log In</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
