import React, {useEffect, useState} from "react";
import {
  Route,
  Routes,
  Link,
  BrowserRouter
} from "react-router-dom";
import Default from "./Default";
import Manage from "./Manage";
import Logout from "./Logout";
import Login from "./Login";
import EditPost from "./EditPost";
import NewPost from "./NewPost";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

export default function Main(){
    const [token, setToken] = useState(localStorage.getItem("AuthToken"));
    const apiUrl = process.env.REACT_APP_API_URL;
    const [IsLoggedIn, setIsLoggedIn] = useState(token !== null);
    const [showModal, setShowModal] = useState(token === null);

    const handleLogout = (event) => {
        event.preventDefault();
        localStorage.removeItem("AuthToken");

        setIsLoggedIn(false);
        setShowModal(false);
    }

    const handleModalShow = () => {
        setShowModal(true);
    };

    useEffect(() => {
        const currentToken = localStorage.getItem("AuthToken");
        setToken(currentToken);
        setIsLoggedIn(currentToken !== null);

    }, [token, IsLoggedIn]);

    return (
        <BrowserRouter>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand href="/">Bloggo</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {IsLoggedIn ? <Nav.Link as={Link} to="/Manage">Manage</Nav.Link> : null }
                                {IsLoggedIn ? <Nav.Link as={Link} to="/NewPost">Create New</Nav.Link> : null }
                                {IsLoggedIn ? <Nav.Link onClick={(e) => handleLogout(e) } to="/logout">Logout</Nav.Link> : <Nav.Link as={Link} to="#" onClick={() => handleModalShow()}>Log In</Nav.Link>}
                                
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div className="content">
                    <Routes>
                        <Route exact path="/" element={<Default />} />
                        {IsLoggedIn ? <Route path="/Manage" element={<Manage />} /> : null}
                        <Route path="/logout" element={<Logout />} />
                        {IsLoggedIn ? <Route path="/NewPost" element={<NewPost />} /> : null }
                        {IsLoggedIn ? <Route path="/edit/:postID" element={<EditPost />} /> : null }
                    </Routes>
                </div>
                <Login showModal={showModal} setShowModal={setShowModal} apiUrl={apiUrl} setIsLoggedIn={setIsLoggedIn}></Login>
        </BrowserRouter>
    )
}