import React, {useState, useEffect} from "react";
import Moment from 'moment';

export default function Default(){
  const [recent, setRecent] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    //attempt to fetch a list of posts
    fetch(apiUrl + '/post/limit/10', {
      method: 'GET',
      headers: {"Authorization": "Bearer " + localStorage.getItem("AuthToken")}
    }).then(postResponse =>{
      if(postResponse.status !== 200){
        alert("Could not fetch post list");
        return;
      }
      
      //if success, console log out the list
      postResponse.json().then(postData => {
        if(postData){
          setRecent(postData);
        }
      });
    })
  }, []);

  return (
      <div className="container">
        {recent.map((post) => 
          <div key={post.postID} className="home-post-content">
            <span className="h3">{post.title}</span> <p>{Moment(post.dateCreated).format('MM-DD-YYYY')}</p>
            <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
          </div>
        )}
      </div>
  );
}