import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Moment from 'moment';

export default function Manage(){
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const token = localStorage.getItem("AuthToken");
    const [IsLoggedIn, setIsLoggedIn] = useState(token !== null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchList = () => {
        //attempt to fetch a list of posts
        fetch(apiUrl + '/posts/byUser', {
          method: 'GET',
          headers: {"Authorization": "Bearer " + token}
        }).then(postResponse =>{
          if(postResponse.status !== 200){
            // if the token is invalid
            if(postResponse.status === 401){
                localStorage.removeItem("AuthToken");
                navigate('/');
                return;
            }

            alert("Could not fetch post list");
            return;
          }
          
          //if success, console log out the list
          postResponse.json().then(postData => {
            if(postData){
                setPosts(postData);
            }
          });
        })

    }

    const updatePost = (post) => {
        post.isPublished = post.isPublished === 0 ? 1 : 0;

        fetch(apiUrl + '/post', {
            method: 'PUT',
            body: JSON.stringify(post),
            headers: {"Authorization": "Bearer " + token}
        })
        .then(postResponse => {
            if(postResponse.status !== 200){
                alert("Could not update the publish status");
                return;
            }
            
            fetchList();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const deletePost = (post) => {
        fetch(apiUrl + '/post/' + post.postID, {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token}
        })
        .then(postResponse => {
            if(postResponse.status !== 200){
                alert("Could not update the publish status");
                return;
            }
            
            fetchList();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    
    const verifyDelete = (post) => {
        if(window.confirm("Are you sure you want to delete this record?")){
            deletePost(post);
        }
    }

    useEffect(() => {
        let authToken = localStorage.getItem("AuthToken");
        setIsLoggedIn(authToken !== null);

        if(!IsLoggedIn){
            navigate('/');
            return;
        }

        fetchList();
    }, []);

    const editPost = (postID) => {
        navigate('/edit/' + postID);
    }

    return(
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Date Created</th>
                    <th>Date Updated</th>
                    <th>Is Published</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {posts.map((post) =>
                    <tr key={post.postID}>
                        <td onClick={() => editPost(post.postID)}>{post.title}</td>
                        <td>{Moment(post.dateCreated).format('MM-DD-YYYY h:mm a')}</td>
                        <td>{Moment(post.dateUpdated).format('MM-DD-YYYY h:mm a')}</td>
                        <td><input type="checkbox" checked={post.isPublished > 0} onChange={() => {updatePost(post)}} ></input></td>
                        <td><button type="button" className="btn btn-danger" onClick={() => {verifyDelete(post)}}>Delete</button></td>
                    </tr>
                )}
            </tbody>
        </Table>
    )
}
