import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";

export default function EditPost(props) {
    const token = localStorage.getItem("AuthToken");
    const apiUrl = process.env.REACT_APP_API_URL;
    let location = useLocation();
    const urlSplit = location.pathname.split('/');
    const postId = urlSplit[urlSplit.length - 1];
    const [formData, setFormData] = useState({
        title:'',
        isPublished: false,
        body: '', 
        postID: postId
    });

    const save = () => {
        //attempt to update the post record
        fetch(apiUrl + '/post', {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {"Authorization": "Bearer " + token}
        })
        .then(postResponse => {
            if(postResponse.status !== 200){
                alert("Could not fetch post data");
                return;
            }
            
            //if success, console log out the list
            postResponse.json().then(postData => {
                setFormData(postData);
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    useEffect(() => {
        //attempt to fetch a list of posts
        fetch(apiUrl + '/post/' + postId, {
          method: 'GET',
          headers: {"Authorization": "Bearer " + token}
        }).then(postResponse =>{
          if(postResponse.status !== 200){
            alert("Could not fetch post data");
            return;
          }
          
          //if success, console log out the list
          postResponse.json().then(postData => {
            setFormData(postData);
          });
        })
    }, []);

    return(
        <div className="container">
            <form>
                <div className="mb-3">
                    <label htmlFor="postTitle" className="form-label">Title</label>
                    <input type="text" className="form-control" id="postTitle" value={formData.title} onChange={(e) => setFormData({...formData, title: e.target.value})} />
                </div>
                <div className="mb-3">
                    <Form.Check id="publishedCheck" type="checkbox" label="Is Published" checked={formData.isPublished} onChange={(e) =>{ setFormData({...formData, isPublished: e.target.checked})}}></Form.Check>
                </div>
                <div className="mb-3">
                    <label htmlFor="postContent" className="form-label">Content</label>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        value={formData.body}
                        onEditorChange={(value) => setFormData({...formData, body: value})}
                        init={{
                            height: 500,
                            menubar: 'file edit view insert format tools table help',
                            plugins: [
                                'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
                            ],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:1rem }'
                        }}
                    />
                </div>
                <Button variant="primary" onClick={save}>Save</Button>
            </form>
        </div>
    )
}
