import {useEffect} from "react";
import { useNavigate } from "react-router-dom";

export default function Logout(){
    const navigate = useNavigate();

    useEffect(() => {
      localStorage.removeItem("AuthToken");
      navigate('/');
    }, [navigate]);

    return(
      <div></div>
    )
    
}