import React, {useState} from "react";
import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

export default function NewPost(){
    const navigate = useNavigate();
    const token = localStorage.getItem("AuthToken");
    const apiUrl = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        title:'',
        body: '', 
    });
    
    const save = (event) => {
        event.preventDefault();

        if(formData.title === ''){
            alert("Title cannot be empty");
            return;
        }

        console.log(formData);
        //attempt to log in and get a token
        fetch(apiUrl + '/post', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {"Authorization": "Bearer " + token}
        })
        .then(postResponse => {
            if(postResponse.status !== 200){
                alert("Could not save post");
                return;
            }
            
            //if success, console log out the list
            postResponse.json().then(postData => {
                console.log(postData);
                navigate('/edit/' + postData.postID);
            });
            
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    return(
        <div className="container">
            <p>Create A New Post</p>
            <form>
                <div className="mb-3">
                    <label htmlFor="postTitle" className="form-label">Title</label>
                    <input type="text" className="form-control" id="postTitle" value={formData.title} onChange={(e) => {setFormData({...formData, title: e.target.value})}} />
                </div>
                <div className="mb-3">
                    <label htmlFor="postContent" className="form-label">Content</label>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        value={formData.body}
                        onEditorChange={(value) => {setFormData({...formData, body: value})}}
                        init={{
                            height: 500,
                            menubar: 'file edit view insert format tools table help',
                            plugins: [
                                'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
                            ],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:1rem }'
                        }}
                    />
                </div>
                <Button variant="primary" onClick={save}>Save</Button>
            </form>
        </div>
    )
}